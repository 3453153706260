<template>
  <div
    v-if="itemList.length"
    class="facts-in-numbers"
    :class="[
      justifyFacts,
      themeColorClass,
      { 'facts-in-numbers--nested': checkboxNested }
    ]"
    :style="baseItemWidth"
  >
    <div class="facts-in-numbers__wrapper row flex-wrap">
      <div
        v-for="item in itemList"
        :key="item.id"
        class="facts-in-numbers__item no-shrink"
      >
        <div class="facts-in-numbers__number">
          {{ item.name }}
        </div>

        <T3HtmlParser class="facts-in-numbers__label" :content="item.text" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { UiCeProductBoxesProps } from '~/types'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<UiCeProductBoxesProps>()
const themeColorClass = useTheming(props.themeColor)

const itemsInRowOptions = ref([3, 4])

const justifyFacts = computed(() => {
  return ['left', 'right'].includes(props.headerPosition)
    ? `facts-in-numbers--${props.headerPosition}`
    : null
})

const baseItemWidth = computed(() => {
  const itemListLength = props.itemList?.length || 0
  const validOptions = itemsInRowOptions.value.filter(
    (inRow) => itemListLength % inRow === 0
  )

  let baseWidth = 50
  let itemsInRow = 2

  if (validOptions.length > 0) {
    baseWidth = Math.round((100 / validOptions[0]) * 100 + Number.EPSILON) / 100
    itemsInRow = validOptions[0]
  }

  return {
    '--base-item-width': baseWidth + '%',
    '--items-in-row': itemsInRow
  }
})
</script>

<style lang="scss">
.facts-in-numbers {
  max-width: rem(880px);
  margin: 0 auto;

  $base: &;

  &--left {
    margin-left: 0;
  }

  &--right {
    margin-right: 0;
  }

  &__wrapper {
    padding: 0;
    text-align: center;
    justify-content: center;

    @include media-query(sm) {
      justify-content: flex-start;
    }
  }

  &__item {
    text-align: center;
    font-weight: 500;
    margin: rem(26px) 0 0;
    padding: em(13px);
    flex-basis: calc(var(--base-item-width));
    min-width: rem(170px);

    @include media-query(sm) {
      text-align: left;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &__number {
    line-height: 1;
    font-size: rem(44px);
    font-weight: 900;
    white-space: nowrap;
  }

  &__label p {
    margin: 0;
    padding-top: spacing(sm);
    font-size: rem(18px);
    line-height: 1.2;
  }

  &--nested {
    & #{$base}__item {
      font-weight: 400;
      padding: 0;
      padding-left: rem(20px);
      position: relative;
      text-align: left;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: rem(4px);
        height: max(rem(75px), 50%);
        width: rem(4px);
        background-color: var(--theme__theme-color);
      }
    }

    & #{$base}__number {
      font-weight: 700;
    }

    & #{$base}__label p {
      font-size: rem(16px);
    }
  }
}
</style>
